.container {
    padding: 2vh 2%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding-top: 30px;
}

.imageContainer {
    display: flex;
    justify-content: space-between;
}

.imgcv {
    width: 637px;
    height: 700px;
    margin: auto;
}

@media (max-width: 1050px) {
    .imgcv {
        width: 430px;
        height: 472px;
        margin: auto;
    }
}

@media (min-width: 1051px) and (max-width: 1150px) {
    .imgcv {
        width: 500px;
        height: 550px;
        margin: auto;
    }
}

@media (min-width: 1151px) and (max-width: 1300px) {
    .imgcv {
        width: 546px;
        height: 600px;
        margin: auto;
    }
}

@media (min-width: 1301px) and (max-width: 1500px) {
    .imgcv {
        width: 620px;
        height: 681px;
        margin: auto;
    }
}

@media (min-width: 1501px) and (max-width: 1800px) {
    .imgcv {
        width: 720px;
        height: 791px;
        margin: auto;
    }
}

@media (min-width: 1801px) {
    .imgcv {
        width: 850px;
        height: 934px;
        margin: auto;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 1rem 0;
}

.headerRigthSide {
    width: 49%;
}

.headerLeftSide {
    width: 49%;
}

.headerTitle {
    margin-bottom: 10px;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 24px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 0.3px;
    font-size: 22px;
    text-align: center;
}

.header p {
    font-size: 14px;
    line-height: 26px;
    color: #6b6f7a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.text {
    width: 800px;
    margin: 20px auto 38px;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 28px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.3px;
    font-size: 22px;
}

.textDay4 {
    font-size: 14px;
    line-height: 26px;
    color: #6b6f7a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 25px;
}