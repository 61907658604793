.wrapper {
    width: 500px;
    padding: 2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.modalTitle {
    font-size: 23px;
    line-height: 26px;
    letter-spacing: 1px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.text {
    font-size: 14px;
    line-height: 26px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.resultTitle {
    font-size: 18px;
    line-height: 26px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin: 20px 0;
}

.result {
    font-size: 14px;
    line-height: 24px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding-left: 3rem;
    position: relative;
    margin-bottom: 20px;
}

.result span {
    color: #169b20;
}

.result::before {
    content: "";
    width: 2px;
    height: 2rem;
    background-color: #c1c1c1;
    position: absolute;
    top: 0;
    left: 2rem;
}

.fatText {
    extends: text;
    font-weight: 600;
    /* margin-bottom: 20px; */
    line-height: 30px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.button {
    width: 100px;
    font-size: 13px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 5px 11px;
    background-color: #ff6b08;
    border: 0;
    outline: none;
    letter-spacing: 1px;
}

.button:hover {
    background-color: #e96105;
}

.unresolvedDaysList {
    margin: 1rem 0 1rem 2rem;
    border-left: 1px solid #c1c1c1;
    padding-left: 10px;
}

.unresolvedDaysList li {
    font-size: 14px;
    line-height: 24px;
    color: #1b4371;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    list-style: none;
    margin-bottom: 10px;
}

.unresolvedDaysList li:last-child {
    margin-bottom: 0;
}

.unresolvedDaysList li span {
    margin-right: 10px;
}

.unresolvedDaysList li a {
    padding-left: 10px;
    border-left: 1px solid #c1c1c1;
    text-decoration: none;
    color: #1b4371;
}