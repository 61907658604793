.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* background-image: url('../../assets/icons/confetti/confetti-1.svg'); */
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.contentWrapper {
    width: 510px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
}

.modalTitle {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 1px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    background-color: #f7f7f7;
    padding: 35px 0 30px;
}

.confetti2 {
    position: absolute;
    top: 25px;
    right: 75px;
    height: 75px;
    width: 40px;
}

.confetti3 {
    position: absolute;
    top: -20px;
    left: 105px;
    height: 50px;
    width: 35px;
}

.confetti4 {
    position: absolute;
    top: 60px;
    left: 50px;
    height: 60px;
    width: 60px;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 58px 58px 58px;
}

.text {
    font-size: 14px;
    line-height: 26px;
    color: #6b6f7a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.resultTitle {
    font-size: 18px;
    line-height: 26px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin: 20px 0;
}

.result {
    font-size: 14px;
    line-height: 24px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding-left: 3rem;
    position: relative;
    margin-bottom: 20px;
}

.result span {
    color: #169b20;
}

.result::before {
    content: "";
    width: 2px;
    height: 2rem;
    background-color: #c1c1c1;
    position: absolute;
    top: 0;
    left: 2rem;
}

.fatText {
    font-size: 14px;
    line-height: 26px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-weight: 600;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.button {
    font-size: 14px;
    line-height: 26px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 15px 30px;
    background-color: #ff6b08;
    border: 0;
    outline: none;
    letter-spacing: 0.3px;
    text-decoration: none;
}

.button:hover {
    background-color: #e96105;
}