@import './libs/normalize.css';
@import './fonts.css';
@import './vars.css';

html {
    box-sizing: border-box;
}

body {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

​img {
    display: block;
    max-width: 100%;
    height: auto;
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: none;
}

.react-sweet-progress-line-inner-status-active {
    background-color: #9ecb45 !important;
}

.react-sweet-progress-symbol {
    visibility: hidden;
}

* {
    box-sizing: border-box;
}

div.browser {
    padding: 1rem 1.5rem;
    border: 1px solid #c1c1c1;
    background-color: #fff;
    margin: 1rem 0 0;
    position: relative;
}

div.warning {
    background-color: rgba(254, 200, 48, 0.1);
    color: #292929;
    font-size: 16px;
    line-height: 26px;
    font-family: "Montserrat", sans-serif;
    margin: 1rem 0;
    padding: 20px;
    border: 1px solid #fec830;
}

.CodeMirror {
    width: 100%;
    height: 38vh;
}

/* стилизация сколла во всем приложении кроме браузера */
* ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track-piece {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: rgba(193, 193, 193, 0.5);
    border-radius: 10px;
}

/* классы для анимации кнопки макет */
.enterButtonLayout {
    background-color: #ff6b08;
    color: white;
    border: 1px solid #ff6b08;
    ;
    transition: background-color 3000ms ease-in-out, color 3000ms ease-in-out, border 3000ms ease-in-out;
}

.exitButtonLayout {
    background-color: #fff;
    color: #6b6f7a;
    border: 1px solid #c1c1c1;
    transition: background-color 3000ms ease-in-out, color 3000ms ease-in-out, border 3000ms ease-in-out;
}

/* анимация 1 */
/* #confetti {
    z-index: 999;
    width: 15px;
    height: 15px;
    background-color: #f2d74e;
    position: absolute;
    left: 50%;
    animation: confetti 5s ease-in-out -2s infinite;
    transform-origin: left top;
}

#confetti:nth-child(1) {
    background-color: #f2d74e;
    left: 10%;
    animation-delay: 0;
}

#confetti:nth-child(2) {
    background-color: #95c3de;
    left: 20%;
    animation-delay: -5s;
}

#confetti:nth-child(3) {
    background-color: #ff9a91;
    left: 30%;
    animation-delay: -3s;
}

#confetti:nth-child(4) {
    background-color: #f2d74e;
    left: 40%;
    animation-delay: -2.5s;
}

#confetti:nth-child(5) {
    background-color: #95c3de;
    left: 50%;
    animation-delay: -4s;
}

#confetti:nth-child(6) {
    background-color: #ff9a91;
    left: 60%;
    animation-delay: -6s;
}

#confetti:nth-child(7) {
    background-color: #f2d74e;
    left: 70%;
    animation-delay: -1.5s;
}

#confetti:nth-child(8) {
    background-color: #95c3de;
    left: 80%;
    animation-delay: -2s;
}

.confetti:nth-child(9) {
    background-color: #ff9a91;
    left: 90%;
    animation-delay: -3.5s;
}

#confetti:nth-child(10) {
    background-color: #f2d74e;
    left: 100%;
    animation-delay: -2.5s;
}

@keyframes confetti {
    0% {
        transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
    }

    25% {
        transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
    }

    50% {
        transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
    }

    75% {
        transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
    }

    100% {
        transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
    }
} */


/* анимация 2 */
@keyframes confetti-slow {
    0% {
        transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }

    100% {
        transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
    }
}

@keyframes confetti-medium {
    0% {
        transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }

    100% {
        transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
    }
}

@keyframes confetti-fast {
    0% {
        transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }

    100% {
        transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
    }
}

.confetti-container {
    perspective: 700px;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.confetti {
    position: absolute;
    z-index: 1;
    top: -10px;
    border-radius: 0%;
}

.confetti--animation-slow {
    animation: confetti-slow 2.25s linear 1 forwards;
}

.confetti--animation-medium {
    animation: confetti-medium 1.75s linear 1 forwards;
}

.confetti--animation-fast {
    animation: confetti-fast 1.25s linear 1 forwards;
}

/* для того чтобы при открытии модального окна не было скролла */
.noscroll {
    overflow: hidden;
}