.main {
    width: 100%;
    height: 87vh;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow-y: auto;
}

.mainContent {
    width: 95%;
    height: 74vh;
    /* overflow-y: auto; */
    margin: 7vh auto 2vh;
    display: flex;
    position: relative;
}

/* НАЧАЛО СТИЛИЗАЦИИ ПРАВОЙ ЧАСТИ (КОД И РЕЗУЛЬТАТ): */

.codeAndResult_container {
    margin-left: 3%;
    width: 67%;
    height: 78vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.code_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.box {
    width: 49%;
    height: 38vh;
    position: relative;
    width: 100%;
}

.containerHTML {
    width: 100%;
    height: 36vh;
}

.containerCSS {
    position: relative;
    width: 100%;
    height: 36vh;
}

.codeTitle {
    position: absolute;
    top: -20px;
    left: 0;
    color: black;
    font-size: 14px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.result_container {
    width: 100%;
    line-height: 0;
    position: relative;
}

/* .result_container::after {
    position: absolute;
    top: 40px;
    left: -48px;
    content: "Результат:";
    transform: rotate(-90deg);
    font-size: 14px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  } */

/* .result_container::after {
    position: absolute;
    top: -15px;
    right: 0;
    content: "Результат:";
    font-size: 14px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  } */


.activeTab {
    background-color: orange;
    color: white;
}